import React from "react";
import Common from "./Common";
import web from "../src/Pictures/img3.png";

function About() {
  return (
    <>
      <Common
        name="We are a leading provider of cloud services, dedicated to empowering businesses with advanced technology solutions. With a firm belief in the transformative power of the cloud, we strive to revolutionize the way organizations operate and accelerate their digital growth with"
        imgsrc={web}
        visit="/contact"
        btname="Contact us"
      />
    </>
  );
}

export default About;
