// services.js
import React from "react";
import Card from "./Card";
import img1 from "../src/Pictures/img1.png";
import img2 from "../src/Pictures/img2.png";
import img3 from "../src/Pictures/img3.png";
import img4 from "../src/Pictures/img4.png";
import img5 from "../src/Pictures/img5.png";
import img6 from "../src/Pictures/img6.png";

function Service() {
  return (
    <>
      <div className="my-5">
        <h1 className="text-center">Our Services</h1>
      </div>

      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-4">
              <Card
                title="Apple Enterprise"
                imgsrc={img1}
                description="Elevating your enterprise with seamless Apple integration, enhancing productivity and innovation."
              />
              <Card
                title="Managed IT Services"
                imgsrc={img2}
                description="Empowering your business with expert IT management, ensuring smooth operations and optimal performance."
              />
              <Card
                title="DevOps Services"
                imgsrc={img3}
                description="Driving innovation and efficiency through DevOps excellence, accelerating your development pipeline and deployment processes."
              />
              <Card
                title="Multi-Cloud Services"
                imgsrc={img4}
                description="Unleashing the power of multiple clouds for your success, providing flexibility, scalability and resilience."
              />
              <Card
                title="Workload Modernization"
                imgsrc={img5}
                description="Transforming your workload for the digital age, optimizing performance and future-proofing your operations."
              />
              <Card
                title="Software & SaaS Applications"
                imgsrc={img6}
                description="Powering your business with cutting-edge software solutions, delivering efficiency, reliability, and innovation."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
